import { createLessContext, UseLessAPI } from '../../hooks/useLess';
import { PositivatorItem } from '../../../client/types';

function initialState() {
  return {
    positivatorModalData: null as null | PositivatorItem
  };
}

type State = ReturnType<typeof initialState>;

export const PositivatorContext = createLessContext({
  factory() {
    return initialState();
  },

  actions(api: UseLessAPI<State>) {
    return {
      closePositivatorModal() {
        api.setState({
          positivatorModalData: null
        });
      },

      openPositivatorModal(positivator: PositivatorItem) {
        api.setState({
          positivatorModalData: positivator
        });
      }
    };
  }
});
