import { Context, mountRequestSignature } from 'graphql-clientgen';

export async function graphqlLogsMiddleware(ctx: Context) {
  if (!CAN_DEBUG()) return ctx;

  const { variables, methodConfig, action, result } = ctx;
  const { schemaKey, appendToFragment, fragment } = methodConfig;
  if (!schemaKey) return ctx;

  const color = rgb(
    mountRequestSignature(
      schemaKey,
      variables,
      `${fragment}${appendToFragment}`
    )
  );

  if (['complete', 'willQueue'].includes(action)) {
    let actionName = '';
    if (action === 'willQueue') {
      actionName = '🚀';
    }
    if (action === 'complete') {
      actionName = '🛩';
    }

    const args: any[] = [
      `%c ${actionName} ${schemaKey}`,
      `background: ${color}; color: #000000; padding: 3px; width: 100%; display: block;`
    ];

    if (Object.keys(variables).length) {
      args.push({ variables });
    }

    if (action === 'complete') {
      args.push({ result });
    }

    console.log(...args);
  }

  return ctx;
}

function rgb(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let c = (hash & 0x00ffffff).toString(16).toUpperCase();
  return '#' + '00000'.substring(0, 6 - c.length) + c;
}

let CAN_DEBUG = () => {
  if (typeof window !== 'undefined') {
    return localStorage.debug || window.location.hostname === 'localhost';
  }
};
