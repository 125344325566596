/**
 * ESSE ARQUIVO ´E UMA COPIA DO USELESS EXISTENTE NO PROJETO UTILS, PQ NAO DA PRA IMPORTAR O UTILS AQUI
 * ELE AJUDA A DIMINUIR A QUANTIDADE DE ESTADO ESPALHADO ENTRE COMPONENTES E CENTRALIZA EM UM UNICO CONTEXT
 */

import * as React from 'react';

export type UseLessAPI<State> = {
  setState(state: Partial<State>): State;
  getState(): State;
  previousState: () => State | null;
};

export function useLess<State, Actions>(
  factory: (prev: State | null) => State,
  deps?: any[]
): State & Actions & UseLessAPI<State> & { api: UseLessAPI<State> };

export function useLess<State, Actions>(
  factory: (prev: State | null) => State,
  actions?: (api: UseLessAPI<State>) => Actions,
  deps?: any[]
): State & Actions & UseLessAPI<State> & { api: UseLessAPI<State> };

export function useLess<State, Actions>(
  args: ArgsObject<State, Actions>
): State & Actions & UseLessAPI<State> & { api: UseLessAPI<State> };

export function useLess<State>(...args: any[]) {
  const { factory, actions, onChange, deps } = parseArgs(...args);

  let isFirstRender = React.useRef(true);

  React.useEffect(() => {
    isFirstRender.current = true;
  }, deps);

  const previousState = React.useRef(null as State | null);

  const initial = React.useMemo(() => {
    const reactiveObject: any = factory(previousState.current);

    const api: UseLessAPI<State> = {
      previousState() {
        return previousState.current ? { ...previousState.current } : null;
      },
      getState() {
        return { ...ref.current };
      },
      setState(s: any) {
        Object.keys(s).forEach(function (k) {
          reactiveObject[k] = s[k];
        });
        return reactiveObject;
      }
    };

    Object.keys(reactiveObject).forEach(function (key) {
      const color = nextColor();
      let internalValue = reactiveObject[key];

      Object.defineProperty(reactiveObject, key, {
        get() {
          return internalValue;
        },
        set(value) {
          if (internalValue === value) return;
          internalValue = value;

          ref.current = { ...ref.current, [key]: value };
          setState(ref.current);

          onChange &&
            onChange(
              // @ts-ignore
              key,
              ref.current,
              api
            );

          if (CAN_DEBUG()) {
            requestAnimationFrame(() => {
              let valueString = ellipsis(
                value?.toString ? value.toString() : value + ''
              );

              if (Array.isArray(value)) {
                valueString = `[${valueString}]`;
              }
              if (valueString?.startsWith('[object Object]')) {
                valueString = '<<object>> 🔽';
              }
              console.groupCollapsed(
                `%c set ${key} to ${valueString} ${new Date().toLocaleTimeString()} `,
                `background: ${color}; color: #000000; padding: 3px; width: 100%; display: block;`
              );
              console.log(value);
              console.trace();
              console.groupEnd();
            });
          }
        },
        enumerable: true
      });
    });

    if (!isFirstRender.current) {
      setState(reactiveObject);
    }

    isFirstRender.current = false;
    previousState.current = { ...reactiveObject };

    Object.keys(api).forEach(function (apiEntry) {
      Object.defineProperty(reactiveObject, apiEntry, {
        get() {
          return (api as any)[apiEntry];
        },
        enumerable: false
      });
    });

    Object.defineProperty(reactiveObject, 'api', {
      get() {
        return api;
      },
      enumerable: false
    });

    if (actions) {
      const currentActions: any = actions(api);
      Object.keys(currentActions).forEach((key) => {
        if (reactiveObject[key]) {
          return console.error(
            `${key} already defined as ${reactiveObject[key]}`
          );
        }
        Object.defineProperty(reactiveObject, key, {
          get() {
            return currentActions[key];
          },
          enumerable: false
        });
      });
    }

    return reactiveObject;
  }, deps);

  const ref = React.useRef<State>(initial);
  const [, setState] = React.useState<State>(initial);

  return initial as any;
}

function nextColor() {
  ++colorIndex;
  if (colorIndex >= colors.length) {
    colorIndex = 0;
  }
  return colors[colorIndex];
}

let colorIndex = -1;

const colors = [
  '#3366E6',
  '#99FF99',
  '#E6B3B3',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#33FFCC',
  '#991AFF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#CC9999',
  '#E6FF80',
  '#1AFF33',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#00B3E6',
  '#E6B333',
  '#E64D66',
  '#FF4D4D',
  '#6666FF'
];

let CAN_DEBUG = () => {
  if (typeof window !== 'undefined') {
    return localStorage.debug || window.location.hostname === 'localhost';
  }
};

type ArgsObject<State, Actions> = {
  factory: (prev: State | null) => State;
  onChange?(key: keyof State, newState: State, api: UseLessAPI<State>): void;
  actions?: (api: UseLessAPI<State>) => Actions;
  deps: any[];
};

function parseArgs<State, Actions>(...args: any[]): ArgsObject<State, Actions> {
  let factory: any;
  let actions: any;
  let deps: any = [];

  if (typeof args[0] === 'function') {
    factory = args[0];

    if (typeof args[1] === 'function') {
      actions = args[1];
    }

    if (Array.isArray(args[1])) {
      deps = args[1];
    }

    if (Array.isArray(args[2])) {
      deps = args[2];
    }

    return {
      factory,
      actions,
      deps
    };
  }

  return args[0];
}

const ellipsis = (str: string, max = 100) =>
  str.length > max ? `${str.substring(0, max)}...` : str;

export function createLessContext<State, Actions>(
  args: Omit<ArgsObject<State, Actions>, 'deps'>
) {
  function _useState(useDepsHook: () => any[]) {
    const deps = useDepsHook();
    return useLess<State, Actions>({ ...args, deps });
  }

  type S = ReturnType<typeof _useState>;

  const Context = React.createContext<S | null>(null);

  function useState() {
    const state = React.useContext(Context);
    if (!state) {
      debugger;
      throw new Error(`Check if Provider was added to react tree`);
    }
    return state;
  }

  function useDefaultDepsHook(): any[] {
    return [];
  }

  function Provider(props: {
    children: (state: S) => React.ReactNode;
    useDepsHook?: () => any[];
  }) {
    const state = _useState(props.useDepsHook || useDefaultDepsHook);

    return (
      <Context.Provider value={state}>{props.children(state)}</Context.Provider>
    );
  }

  return {
    useState,
    Provider
  };
}

export type LessStateType<
  Context extends ReturnType<typeof createLessContext>
> = ReturnType<Context['useState']>;
