import { Context, Middleware } from 'graphql-clientgen';
import { UserSession } from './UserSession';
import { message } from 'antd';

export const graphqlAuthMiddleware: Middleware = async (ctx) => {
  handleApiErrors(ctx);

  const token = UserSession.getToken();

  if (token) {
    ctx.requestConfig.headers = {
      ...ctx.requestConfig.headers,
      Authorization: `bearer ${token}`
    };
  }

  // quando usamos um token temporario, a api pode retornar
  // um novo token permanente no response header authorization
  // quando isso ocorre, salvamos o novo token permanente
  if (ctx.fetchResponse) {
    const newToken = ctx.fetchResponse.headers.get('authorization');
    if (newToken) {
      UserSession.setItem('temp_token', newToken);
    }
  }

  return ctx;
};

const handleApiErrors = (ctx: Context) => {
  if (!ctx.errors) return;
  if (ctx.action !== 'complete') return;
  if (ctx.methodConfig.entityName === 'User') return; // nao notificar 401 quando fetch de user

  const error = ctx.errors.join();

  ctx.errors.forEach(function (err: string | Record<string, any>) {
    const msg = typeof err === 'string' ? err : err.message;
    if (msg === '401' || msg.match(/Unauthorized/i)) {
      localStorage.clear();
      if (!window.location.pathname.match(/login/)) {
        window.location.href = '/';
      }
    }
  });

  if (typeof window !== 'undefined') {
    if (error.match(/CLIENT_INFO::/)) {
      return message.info(error.replace('CLIENT_INFO::', ''));
    }

    if (error.match(/CLIENT_WARNING::/)) {
      return message.warn(error.replace('CLIENT_WARNING::', ''));
    }

    message.error(`${ctx.methodConfig.entityName}: ${error}`);
  }

  return ctx;
};
